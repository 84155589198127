.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}


.image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  cursor: pointer;
}

.viewer-image {
  max-width: 80%;
  max-height: 80%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.previous-button,
.next-button {
  position: absolute;
  top: 50%;
  font-size: 44px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transform: translateY(-50%);
}

.previous-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

@media (min-width: 768px) {
  .gallery-image {
    width: 200px;
    height: 200px;
    margin: 10px;
  }

  .viewer-image {
    max-width: 60%;
    max-height: 60%;
  }
}

.close-button:hover,
.previous-button:hover,
.next-button:hover {
  background-color: rgba(3, 101, 146, 0.8);
}

.close-button:hover,
.previous-button:hover::before,
.next-button:hover::before {
  color: #fff;
}

.zoom-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.zoom-in-button,
.zoom-out-button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.zoom-in-button:hover,
.zoom-out-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.viewer-image {
  max-width: 80%;
  max-height: 80%;
  transition: transform 0.3s ease;
  cursor: zoom-in;
}

.viewer-image.zoomed {
  transform: scale(1.5);
  cursor: zoom-out;
}

/* Estilo para el desbordamiento oculto de la página cuando se muestra la imagen abierta */
body.no-scroll {
  overflow: hidden;
}

/* Estilo para el cursor normal en el resto de la página */
body:not(.no-scroll) {
  cursor: default;
}
